import {
  Employee,
  MembershipPost,
} from "applications/kernels/organizations/reorganizations/compornents/employee/EmployeeChangeModal/types";
import i18next from "i18next";
import React from "react";
import { EmployeeChangeBaseModal } from "./BaseModal";

interface Props {
  readonly show: boolean;
  readonly employee: Employee;
  readonly posts: MembershipPost[];
  readonly onChange: (employee: Employee) => void;
  readonly onCancel: () => void;
}

/**
 * 組織マスタ従業員変更: 従業員情報変更編集モーダル
 */
export const EmployeeChangeEditModal: React.FC<Props> = (props) => {
  return (
    <EmployeeChangeBaseModal
      title={i18next.t(
        "reorganizations.changeEmployees.modal.employeeChange.titleEdit",
      )}
      isCreate={false}
      show={props.show}
      employee={props.employee}
      posts={props.posts}
      onChange={props.onChange}
      onCancel={props.onCancel}
    />
  );
};
