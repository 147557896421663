import { CheckBox } from "applications/kernels/organizations/design-system/components";
import {
  Membership,
  MembershipPost,
} from "applications/kernels/organizations/reorganizations/compornents/employee/EmployeeChangeModal/types";
import i18next from "i18next";
import React from "react";
import Select from "react-select";
import { AsyncPaginate } from "react-select-async-paginate";
import styled from "styled-components";
import { useFetchDepartments } from "../../hooks/useFetchDepartments";
import { useMembershipEdit } from "../../hooks/useMembershipEdit";

const Layout = styled.div<{ isMain: boolean }>`
  display: flex;
  gap: 8px;
  flex-direction: row;
  align-items: flex-start;

  background-color: ${(props): string =>
    props.isMain ? "#dee8ff" : "#f5f5f5"};
  padding: 16px;
  border-radius: 8px;
`;

const FieldListLayout = styled.div`
  flex-grow: 1;
  display: grid;
  grid-template-columns: auto 1fr;
  gap: 8px;
  align-items: center;
`;

const DeleteMembershipButton = styled.div`
  flex-grow: 0;

  color: #666;
  cursor: pointer;
  border-radius: 16px;
  text-align: center;
  width: 24px;
  height: 24px;

  &:hover {
    background-color: #f5f5f5;
  }

  &:before {
    content: "✕";
  }
`;

interface Props {
  readonly isMain?: boolean;
  /**
   * 所属
   */
  readonly membership: Membership;
  /**
   * 役職リスト
   */
  readonly posts: MembershipPost[];
  /**
   * 削除ボタンが押されたとき
   */
  readonly onRemove: () => void;
  /**
   * 所属部署が変更されたとき
   */
  readonly onChange: (newMembership: Membership) => void;
}

/**
 * 所属部署編集欄
 */
export const MembershipEditField: React.FC<Props> = ({
  isMain = false,
  membership,
  posts,
  onRemove,
  onChange,
}) => {
  const { loadDepartments } = useFetchDepartments();
  const { handleChangeDepartment, handleChangePost, handleChangeIsApprover } =
    useMembershipEdit(membership, onChange);

  const formatDepartmentLabel = ({ name, parents }): JSX.Element => {
    return (
      <div>
        <div style={{ color: "#666", fontSize: "12px" }}>
          {parents.map((d): string => d.name).join(" / ")}
        </div>
        <div>{name}</div>
      </div>
    );
  };

  return (
    <Layout isMain={isMain}>
      <FieldListLayout>
        <div>
          {isMain &&
            i18next.t(
              "reorganizations.changeEmployees.modal.employeeChange.mainMembership",
            )}
          {!isMain &&
            i18next.t(
              "reorganizations.changeEmployees.modal.employeeChange.membership",
            )}
        </div>
        <AsyncPaginate
          value={membership.department}
          loadOptions={loadDepartments}
          onChange={handleChangeDepartment}
          additional={{ offset: 0 }}
          isClearable
          placeholder={i18next.t(
            "reorganizations.changeEmployees.modal.employeeChange.searchMembershipPlaceholder",
          )}
          formatOptionLabel={formatDepartmentLabel}
          getOptionValue={(d): string => d.id}
          getOptionLabel={(d): string => d.name}
          menuPortalTarget={document.body}
          styles={{
            menuPortal: (base) => ({ ...base, zIndex: 9999 }),
          }}
        />
        <div>
          {i18next.t(
            "reorganizations.changeEmployees.modal.employeeChange.post",
          )}
        </div>
        <Select
          value={membership.post}
          options={posts}
          getOptionValue={(p): string => p.id}
          getOptionLabel={(p): string => p.name}
          isClearable
          placeholder={i18next.t(
            "reorganizations.changeEmployees.modal.employeeChange.searchPostPlaceholder",
          )}
          onChange={handleChangePost}
          menuPortalTarget={document.body}
          styles={{
            menuPortal: (base) => ({ ...base, zIndex: 9999 }),
          }}
        />
        <div>
          {i18next.t(
            "reorganizations.changeEmployees.modal.employeeChange.approver",
          )}
        </div>
        <div>
          <CheckBox
            isChecked={membership.isApprover}
            onChange={handleChangeIsApprover}
          />
        </div>
      </FieldListLayout>
      <DeleteMembershipButton onClick={(): void => onRemove()} />
    </Layout>
  );
};
