import {
  Employee,
  Membership,
} from "applications/kernels/organizations/reorganizations/compornents/employee/EmployeeChangeModal/types";
import { ReorganizationsChangesEmployeesCreateRequestMembership } from "utilities/api/requests/kernels/organizations/reorganizations/changes/EmployeesCreateRequest";

/**
 * 従業員変更の登録リクエストの所属リストを構築します。
 * 削除予定の所属も含めて構築します。
 * @param newEmployee 変更後の従業員情報
 */
export const buildRequestMemberships = (
  newEmployee: Employee,
): ReorganizationsChangesEmployeesCreateRequestMembership[] => {
  if (!newEmployee.change) return [];

  // new memberships
  const newMemberships = newEmployee.change.newUser.memberships.map(
    (m, index) => mapToRequestMembership(m, index, false),
  );
  // will be deleted memberships
  const willBeDeletedMemberships = newEmployee.user.memberships
    .filter((m) => !newMemberships.some((newM) => newM.id === m.department?.id))
    .map((m, index) =>
      mapToRequestMembership(m, newMemberships.length + index, true),
    );

  return [...newMemberships, ...willBeDeletedMemberships];
};

/**
 * Membershipをリクエストへ変換
 */
const mapToRequestMembership = (
  membership: Membership,
  index: number,
  willBeDeleted: boolean,
): ReorganizationsChangesEmployeesCreateRequestMembership => ({
  id: membership.department?.id || "",
  postId: membership.post?.id || null,
  sort: index + 1,
  isApprover: membership.isApprover,
  willBeDeleted,
});
