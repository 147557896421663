import {
  ChangeMembership,
  Employee,
  EmployeeChange,
  Membership,
  MembershipDepartment,
  MembershipPost,
} from "applications/kernels/organizations/reorganizations/compornents/employee/EmployeeChangeModal/types";
import {
  ReorganizationsChangesEmployeesIndexDetailMembershipResponse,
  ReorganizationsChangesEmployeesIndexDetailNewUserMembershipResponse,
  ReorganizationsChangesEmployeesIndexDetailPostResponse,
  ReorganizationsChangesEmployeesIndexDetailResponse,
} from "utilities/api/responses/kernels/organizations/reorganizations/changes/EmployeesIndexResponse";

/**
 * 従業員情報のAPIレスポンスを変換: MembershipDepartment
 */
const convertResponseToMembershipDepartment = (
  membership: ReorganizationsChangesEmployeesIndexDetailMembershipResponse,
): MembershipDepartment | null => {
  return {
    id: membership.id,
    name: membership.name,
    parents: membership.parents,
  };
};

/**
 * 従業員情報のAPIレスポンスを変換: MembershipPost
 */
const convertResponseToMembershipPost = (
  post: ReorganizationsChangesEmployeesIndexDetailPostResponse | null,
): MembershipPost | null => {
  if (!post) return null;
  return {
    id: post.postId,
    name: post.postName,
  };
};

/**
 * 従業員情報のAPIレスポンスを変換: ChangeMembership
 */
const convertResponseToChangeMembership = (
  memberships: ReorganizationsChangesEmployeesIndexDetailNewUserMembershipResponse[],
): ChangeMembership[] => {
  return memberships.map(
    (m): ChangeMembership => ({
      department: convertResponseToMembershipDepartment(m),
      willBeDeleted: m.willBeDeleted,
      post: convertResponseToMembershipPost(m.post),
      isApprover: m.isApprover,
    }),
  );
};

/**
 * 従業員情報のAPIレスポンスを変換: Membership
 */
const convertResponseToMembership = (
  memberships: ReorganizationsChangesEmployeesIndexDetailMembershipResponse[],
): Membership[] => {
  return memberships.map(
    (m): Membership => ({
      department: convertResponseToMembershipDepartment(m),
      post: convertResponseToMembershipPost(m.post),
      isApprover: m.isApprover,
    }),
  );
};

/**
 * 従業員情報のAPIレスポンスを変換: EmployeeChange
 */
const convertResponseToEmployeeChange = (
  response: ReorganizationsChangesEmployeesIndexDetailResponse,
): EmployeeChange | null => {
  if (!response.change) return null;

  // 元々の従業員情報
  const oldUser = {
    id: response.change?.oldUser.id,
    name: response.change?.oldUser.name,
    employeeId: response.change?.oldUser.employeeId,
    email: response.change?.oldUser.email,
    memberships: convertResponseToMembership(
      response.change?.oldUser.memberships,
    ),
  };
  // 変更後の従業員情報
  const newUser = {
    memberships: convertResponseToChangeMembership(
      response.change?.newUser.memberships,
    ),
  };
  return {
    id: response.change?.id,
    oldUser,
    newUser,
  };
};

/**
 * 従業員情報のAPIレスポンスを変換: Employee
 */
export const convertResponseToEmployee = (
  response: ReorganizationsChangesEmployeesIndexDetailResponse,
): Employee => {
  return {
    user: {
      id: response.user.id,
      name: response.user.name,
      email: response.user.email,
      employeeId: response.user.employeeId,
      memberships: response.user.memberships.map((m) => ({
        department: convertResponseToMembershipDepartment(m),
        post: convertResponseToMembershipPost(m.post),
        isApprover: m.isApprover || false,
      })),
    },
    change: convertResponseToEmployeeChange(response),
  };
};
