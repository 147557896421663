import {
  Membership,
  MembershipDepartment,
  MembershipPost,
} from "applications/kernels/organizations/reorganizations/compornents/employee/EmployeeChangeModal/types";
import { useCallback } from "react";

interface HooksReturnType {
  readonly handleChangeDepartment: (
    newDepartment: MembershipDepartment | null,
  ) => void;
  readonly handleChangePost: (newPost: MembershipPost | null) => void;
  readonly handleChangeIsApprover: (isChecked: boolean) => void;
}

export const useMembershipEdit = (
  membership: Membership,
  onChange: (newMembership: Membership) => void,
): HooksReturnType => {
  const handleChangeDepartment = useCallback(
    (newDepartment: MembershipDepartment | null): void => {
      onChange({ ...membership, department: newDepartment });
    },
    [onChange, membership],
  );
  const handleChangePost = useCallback(
    (newPost: MembershipPost | null): void => {
      onChange({ ...membership, post: newPost });
    },
    [onChange, membership],
  );
  const handleChangeIsApprover = useCallback(
    (isChecked: boolean): void => {
      onChange({ ...membership, isApprover: isChecked });
    },
    [onChange, membership],
  );

  return {
    handleChangeDepartment,
    handleChangePost,
    handleChangeIsApprover,
  };
};
