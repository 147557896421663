import i18next from "i18next";
import React from "react";
import styled from "styled-components";
import { ReorganizationsChangesEmployeesIndexDetailMembershipResponse } from "utilities/api/responses/kernels/organizations/reorganizations/changes/EmployeesIndexResponse";

const Item = styled.div`
  display: flex;
  align-items: center;
  padding: 16px 0;
`;
const Line = styled.div`
  height: 1px;
  background: #ececec;
`;
const Post = styled.div`
  color: #606060;
  margin-left: 8px;
`;
const Approver = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 24px;
  min-height: 24px;
  color: white;
  background: #aeaeae;
  border-radius: 4px;
  margin-right: 8px;
  font-weight: bold;
`;

interface Props {
  memberships: ReorganizationsChangesEmployeesIndexDetailMembershipResponse[];
}

/**
 * 組織マスタ従業員一覧テーブル: 部署カラム
 */
export const ReorganizationsChangeEmployeesTableMemberships: React.FC<Props> = (
  props,
): JSX.Element => {
  /**
   * 親部署名/部署名の形式で返す
   */
  const getMembershipPath = (
    membership: ReorganizationsChangesEmployeesIndexDetailMembershipResponse,
  ): string => {
    const parentNames = membership.parents.map((p) => p.name);
    return [...parentNames, membership.name].join(" / ");
  };

  return (
    <>
      {props.memberships.map((m, index) => {
        const path = getMembershipPath(m);
        const postName = m.post
          ? m.post?.postName
          : i18next.t("reorganizations.changeEmployees.table.columns.noPost");
        return (
          <React.Fragment key={`employees-table-memberships-post-${m.id}`}>
            {index !== 0 && <Line />}
            <Item>
              {m.isApprover && (
                <Approver>
                  {i18next.t(
                    "reorganizations.changeEmployees.table.columns.approver",
                  )}
                </Approver>
              )}
              {path}
              <Post>({postName})</Post>
            </Item>
          </React.Fragment>
        );
      })}
    </>
  );
};
