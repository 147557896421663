import { Button } from "components/renewaled_ui/buttons";
import i18next from "i18next";
import React from "react";
import styled from "styled-components";

const Layout = styled.div`
  margin-left: auto;
  display: flex;
  gap: 8px;
`;

interface Props {
  readonly disable: boolean;
  readonly isCreate: boolean;
  readonly onCancel: () => void;
  /**
   * 元に戻すボタンが押されたとき
   */
  readonly onReset: () => void;
  /**
   * 保存ボタンが押されたとき
   */
  readonly onSave: () => void;
}

/**
 * 操作ボタン
 */
export const ActionButtons: React.FC<Props> = ({
  disable,
  isCreate,
  onCancel,
  onReset,
  onSave,
}) => {
  return (
    <Layout>
      <Button onClick={onCancel} styleType="link-secondary">
        {i18next.t("commons.actions.cancel")}
      </Button>
      <Button onClick={onReset} styleType="secondary">
        {i18next.t("reorganizations.changeEmployees.actions.reset")}
      </Button>
      <Button onClick={onSave} styleType="primary" disabled={disable}>
        {isCreate
          ? i18next.t("reorganizations.changeEmployees.actions.create")
          : i18next.t("reorganizations.changeEmployees.actions.edit")}
      </Button>
    </Layout>
  );
};
