import {
  ChangeMembership,
  Employee,
  Membership,
} from "applications/kernels/organizations/reorganizations/compornents/employee/EmployeeChangeModal/types";
import { useCallback, useEffect, useState } from "react";

interface Props {
  /**
   * 従業員情報
   */
  readonly employee: Employee;
  /**
   * 登録モードか
   */
  readonly isCreate: boolean;
  /**
   * 保存ボタンが押されたとき
   */
  readonly onChange: (employee: Employee) => void;
}

interface HooksReturnType {
  /**
   * 変更があるか
   */
  readonly hasDiff: boolean;
  /**
   * 所属部署のリスト
   */
  readonly memberships: Membership[];
  /**
   * 所属部署を追加したとき
   */
  readonly handleAddMembership: (index: number) => void;
  /**
   * 所属部署を削除したとき
   */
  readonly handleRemoveMembership: (index: number) => void;
  /**
   * 所属部署をリセットしたとき
   */
  readonly handleResetMemberships: () => void;
  /**
   * 所属部署が変更されたとき
   */
  readonly handleChangeMembership: (
    index: number,
    newMembership: Membership,
  ) => void;
  /**
   * 保存ボタンが押されたとき
   */
  readonly handleClickSave: () => void;
}

/**
 * 未入力の所属部署
 */
const emptyMembership: ChangeMembership = {
  department: null,
  post: null,
  isApprover: false,
  willBeDeleted: false,
};

const initMemberships = (
  initialMemberships: (Membership | ChangeMembership)[],
  isCreate: boolean,
): ChangeMembership[] => {
  // 部署が一つもない場合は、未入力の主所属部署を返す
  if (initialMemberships.length === 0) return [emptyMembership];
  if (!isCreate) return initialMemberships as ChangeMembership[];
  return initialMemberships.map((m) => ({
    ...m,
    willBeDeleted: false,
  }));
};

export const useMembershipListEdit = ({
  employee,
  onChange,
  isCreate,
}: Props): HooksReturnType => {
  const [editedMemberships, setEditedMemberships] = useState<
    ChangeMembership[]
  >([]);
  const changedMemberships =
    employee.change?.newUser?.memberships?.filter(
      (m): boolean => !m.willBeDeleted,
    ) || [];
  const currentMemberships: (ChangeMembership | Membership)[] = isCreate
    ? employee.user.memberships
    : changedMemberships;
  const initialMembershipsStr = JSON.stringify(
    currentMemberships.map((m) => ({
      ...m,
      willBeDeleted: false,
    })),
  );

  /**
   * 部署一覧レスポンスを部署配列に追加
   * 部署が一つもない場合は、初期選択欄として未入力の部署を追加する
   */
  useEffect(() => {
    setEditedMemberships(initMemberships(currentMemberships, isCreate));
  }, [initialMembershipsStr]); // eslint-disable-line react-hooks/exhaustive-deps

  /**
   * 変更があるか
   */
  const hasDiff = (): boolean => {
    const emptyMembershipsStr = JSON.stringify([emptyMembership]);
    const selectedMembershipsStr = JSON.stringify(editedMemberships);
    // 初期状態の場合は変更なし
    if (emptyMembershipsStr === selectedMembershipsStr) return false;
    // 一つでも部署に空欄がある
    const emptyMembershipExist = editedMemberships.some((m) => !m.department);
    if (emptyMembershipExist) return false;
    // 初期状態を消して部署がなくなり、レスポンスも空なら変更なし
    if (editedMemberships.length === 0 && currentMemberships.length === 0)
      return false;
    // 選択した部署がレスポンスと違う
    return selectedMembershipsStr !== initialMembershipsStr;
  };

  const handleAddMembership = useCallback((index: number) => {
    setEditedMemberships((prev) => {
      // 未選択の所属部署を追加
      const newMemberships = [...prev];
      newMemberships.splice(index, 0, emptyMembership);
      return newMemberships;
    });
  }, []);

  const handleRemoveMembership = useCallback((index: number) => {
    setEditedMemberships((prev) => {
      const newMemberships = [...prev];
      newMemberships.splice(index, 1);
      return newMemberships;
    });
  }, []);

  /**
   * 元に戻すを押した
   */
  const handleResetMemberships = useCallback(() => {
    setEditedMemberships(initMemberships(currentMemberships, isCreate));
  }, [employee]);

  const handleChangeMembership = useCallback(
    (index: number, newMembership: Membership) => {
      setEditedMemberships((prev) => {
        const newMemberships = [...prev];
        newMemberships[index] = { ...newMembership, willBeDeleted: false };
        return newMemberships;
      });
    },
    [],
  );

  /**
   * 保存ボタンが押された
   */
  const handleClickSave = useCallback(() => {
    const newEmployee = {
      ...employee,
      // 変更後の従業員情報
      change: {
        id: null,
        // 元々の従業員情報
        oldUser: employee.user,
        newUser: {
          ...employee.user,
          // 変更後の所属部署
          memberships: editedMemberships,
        },
      },
    };

    onChange(newEmployee);
  }, [employee, editedMemberships, onChange]);

  return {
    hasDiff: hasDiff(),
    memberships: editedMemberships,
    handleAddMembership,
    handleRemoveMembership,
    handleResetMemberships,
    handleChangeMembership,
    handleClickSave,
  };
};
