import { MembershipDepartment } from "applications/kernels/organizations/reorganizations/compornents/employee/EmployeeChangeModal/types";
import { debounce } from "lodash";
import { useCallback, useEffect, useState } from "react";
import ApiV3 from "utilities/api_v3";

const PAGE_SIZE = 100;

interface LoadOptions {
  readonly options: MembershipDepartment[];
  readonly hasMore: boolean;
  readonly additional?: { offset: number };
}
interface HooksReturnType {
  readonly loadDepartments: (
    inputValue: string,
    loadedOptions: unknown[],
    additional?: { offset?: number },
  ) => Promise<LoadOptions>;
}

export const useFetchDepartments = (): HooksReturnType => {
  const [hasMore, setHasMore] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");

  const fetchDepartments = async (
    query: string,
    newOffset: number,
  ): Promise<LoadOptions> => {
    if (isLoading || (!hasMore && newOffset !== 0))
      return { options: [], hasMore: false };

    setIsLoading(true);

    try {
      const response =
        await ApiV3.kernels.organizations.departments.managements.search({
          offset: newOffset,
          limit: PAGE_SIZE,
          keyword: query,
        });

      const newDepartments = response.data as MembershipDepartment[];
      const hasMoreResults = newOffset + PAGE_SIZE < response.pagination.count;

      setHasMore(hasMoreResults);

      return {
        options: newDepartments,
        hasMore: hasMoreResults,
        additional: { offset: newOffset + PAGE_SIZE },
      };
    } catch (error) {
      return { options: [], hasMore: false };
    } finally {
      setIsLoading(false);
    }
  };

  // IME の入力中に API を呼び出すと、入力が途切れるたびに API が呼ばれるため、
  // 一定時間入力が途切れてから API を呼ぶようにする
  const debouncedFetchDepartments = useCallback(
    debounce((query: string): void => {
      setHasMore(true);
      fetchDepartments(query, 0);
    }, 300),
    [],
  );

  useEffect(() => {
    if (searchQuery) {
      debouncedFetchDepartments(searchQuery);
    }
  }, [searchQuery]); // eslint-disable-line react-hooks/exhaustive-deps

  const loadDepartments = async (
    inputValue: string,
    _loadedOptions: unknown[],
    additional: { offset?: number } = {},
  ): Promise<LoadOptions> => {
    const newOffset = additional?.offset ?? 0;

    if (inputValue !== searchQuery) {
      setSearchQuery(inputValue);
      return { options: [], hasMore: true, additional: { offset: 0 } };
    }

    return fetchDepartments(searchQuery, newOffset);
  };

  return { loadDepartments };
};
