import {
  Membership,
  MembershipPost,
} from "applications/kernels/organizations/reorganizations/compornents/employee/EmployeeChangeModal/types";
import i18next from "i18next";
import React from "react";
import styled from "styled-components";
import { MembershipEditField } from "../MembershipEditField";

const Layout = styled.div`
  display: flex;
  gap: 8px;
  flex-direction: column;
`;

const AddMembershipButton = styled.div`
  color: #666;
  cursor: pointer;
  border: 1px solid #999;
  border-radius: 16px;
  padding: 4px;
  text-align: center;
  max-width: 160px;

  &:hover {
    background-color: #f5f5f5;
  }

  &:before {
    content: "＋";
  }
`;

interface Props {
  /**
   * 所属部署リスト
   * 0番目は主所属部署を表す
   */
  readonly memberships: Membership[];
  /**
   * 役職一覧
   */
  readonly posts: MembershipPost[];
  /**
   * 所属部署を追加したとき
   */
  readonly onAdd: (index: number) => void;
  /**
   * 所属部署を削除したとき
   */
  readonly onRemove: (index: number) => void;
  /**
   * 所属部署を変更したとき
   */
  readonly onChange: (index: number, newMembership: Membership) => void;
}

/**
 * 所属部署リスト編集欄
 */
export const MembershipListEditField: React.FC<Props> = ({
  memberships,
  posts,
  onAdd,
  onRemove,
  onChange,
}) => {
  return (
    <Layout>
      <AddMembershipButton onClick={(): void => onAdd(0)}>
        {i18next.t(
          "reorganizations.changeEmployees.modal.employeeChange.addMainMembership",
        )}
      </AddMembershipButton>
      {memberships.map((membership, index) => (
        <Layout key={`membership-list-${index}`}>
          <MembershipEditField
            isMain={index === 0}
            membership={membership}
            posts={posts}
            onRemove={(): void => onRemove(index)}
            onChange={(newMembership): void => onChange(index, newMembership)}
          />
          <AddMembershipButton onClick={(): void => onAdd(index + 1)}>
            {i18next.t(
              "reorganizations.changeEmployees.modal.employeeChange.addMembership",
            )}
          </AddMembershipButton>
        </Layout>
      ))}
    </Layout>
  );
};
