import { EmployeeUser } from "applications/kernels/organizations/reorganizations/compornents/employee/EmployeeChangeModal/types";
import React from "react";
import styled from "styled-components";

const Layout = styled.div`
  .name {
    font-size: 1.6rem;
    font-weight: bold;
  }
  .email {
    color: #666;
  }
`;

interface Props {
  readonly user: EmployeeUser;
}

/**
 * 従業員情報表示欄
 */
export const EmployeeField: React.FC<Props> = ({ user }) => {
  return (
    <Layout>
      <div className="name">{user.name}</div>
      <div className="email">{user.email}</div>
    </Layout>
  );
};
