import { ReorganizationsChangeDeleteConfirmModal } from "applications/kernels/organizations/reorganizations/compornents/DeleteConfirmModal";
import i18next from "i18n";
import React from "react";

interface Props {
  isVisible: boolean;
  handleClickDelete: () => void;
  handleClose: () => void;
}

/**
 * 組織マスタ従業員一覧: 取り消し確認モーダル
 */
export const ReorganizationsChangeEmployeesReverseConfirmModal: React.FC<
  Props
> = (props): JSX.Element => {
  return (
    <ReorganizationsChangeDeleteConfirmModal
      isVisible={props.isVisible}
      title={i18next.t(
        "reorganizations.changeEmployees.modal.employeeReverseChange.title",
      )}
      deleteText={i18next.t("reorganizations.changeEmployees.actions.reverse")}
      handleClickDelete={props.handleClickDelete}
      handleClose={props.handleClose}
    />
  );
};
